import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    selectedTab: "WaveForm Analysis",
};

const tabStatusSlice = createSlice({
    name: "tabStatus",
    initialState: INITIAL_STATE,
    reducers: {
        setSelectedTab(state, action) {
            // clear the api call
            if (action.payload !== "Real-Time Metering" && window.realTimeMeteringTimeId !== undefined) {
                clearInterval(window.realTimeMeteringTimeId);
            }
            if (action.payload !== "RealTime Waveforms") {
                clearInterval(window.waveformMeteringId);
            }
            return {
                ...state,
                selectedTab: action.payload,
            };
        },
    },
});

export const tabStatusActions = tabStatusSlice.actions;

export default tabStatusSlice;
