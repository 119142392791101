import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import "./EquipmentCenteredOnZeroStatus.css";

const EquipmentConnStatus = () => {
    const status = useSelector((state) => state.equipmentsPortfolio.equipmentCenteredOnZeroStatus);

    const is_voltage_centered = status.is_voltage_centered_on_zero === "True";
    const is_current_centered = status.is_current_centered_on_zero === "True";
    const displayAlert = !(is_current_centered && is_voltage_centered);

    return (
        <React.Fragment>
            {displayAlert ? (
                <>
                    <br></br>
                    <Row className="justify-content-md-center">
                        <Col className="col-11 fault-alert-box">
                            <text>
                                {(is_current_centered ? "" : "Selected Equipment Current is Not Centered on Zero\n") +
                                    (is_voltage_centered
                                        ? ""
                                        : "Selected Equipment Voltage is Not Centered on Zero\n") +
                                    status.timestamp}
                            </text>
                        </Col>
                    </Row>
                </>
            ) : null}
        </React.Fragment>
    );
};

export default EquipmentConnStatus;
