import DatePicker from 'react-datepicker';
import { addDays } from 'date-fns';

const DateTimePicker = (props) => {
	return (
		<DatePicker
			showTimeSelect
			timeFormat='HH:mm'
			minDate={new Date(2014, 12, 12)}
			selected={props.selectedDate}
			onChange={(date) => {
				props.onDateTimeChange(date);
			}}
			timeInputLabel='Time:'
			timeIntervals={15}
			placeholderText='Mon dd, YYYY HH:MM:SS'
			dateFormat='MMM d, yyyy HH:mm:ss'
			maxDate={addDays(new Date(), 0)}
		/>
	);
};

export default DateTimePicker;
