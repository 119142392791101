import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import BootstrapTable from "react-bootstrap-table-next";
import "../../css/LoadingBars.css";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import ToolkitProvider, {
    CSVExport,
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
const { ExportCSVButton } = CSVExport;

const { SearchBar, ClearSearchButton } = Search;

const eventGroups = {
    VIGroup: ["I RMS", "I Peak", "V RMS", "V Peak", "I Freq", "V Freq"],
    PQGroup: [
        "pq_delta_i",
        "pq_delta_if",
        "pq_delta_v",
        "pq_delta_vf",
        "pqd_imin",
        "pqd_imax",
        "pqd_vmin",
        "pqd_vmax",
        "Single Phasing Count",
    ],
    dtGroup: [
        "ia_max_di_dt",
        "ia_max_di_dt_secs",
        "ib_max_di_dt",
        "ib_max_di_dt_secs",
        "ic_max_di_dt",
        "ic_max_di_dt_secs",
        "Loss of Phase (dVdT)",
        "dVdT Sec",
    ],
    powerDropGroup: ["Power Drop Sec", "Secs abv NP+SF", "Area abv NP+SF", "Single Phasing Count"],
    imbalanceGroup: [
        "V Sag",
        "V Sag Secs",
        "V Imbalance Change",
        "V Imbalance Change Secs",
        "LT V Imbalance",
        "ST V Imbalance",
        "Max V Drop",
        "Max V Drop Secs",
        "I Imbalance",
        "Max I Imbalance",
        "V Imbalance",
        "Max V Imbalance",
    ],
};

export default function EventsSortingTable({ rows, columns, setTabData, selectedEquipment, tabData }) {
    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        hideSelectColumn: true,
        bgColor: "#9bcaff",
    };

    const [columnsList, setColumnsList] = useState(columns);

    const [powerDropGroup, setPowerDropGroup] = useState(false);
    const [VIGroup, setVIGroup] = useState(true);
    const [PQGroup, setPQGroup] = useState(false);
    const [dtGroup, setdtGroup] = useState(false);
    const [imbalanceGroup, setImbalanceGroup] = useState(false);

    useEffect(() => {
        if (!Array.isArray(columns)) {
            setColumnsList([]);
            return;
        }

        const cols = columns.map((col) => {
            if (eventGroups["powerDropGroup"].includes(col["dataField"])) {
                return {
                    ...col,
                    hidden: !powerDropGroup,
                };
            }
            if (eventGroups["VIGroup"].includes(col["dataField"])) {
                return {
                    ...col,
                    hidden: !VIGroup,
                };
            } else if (eventGroups["PQGroup"].includes(col["dataField"])) {
                return {
                    ...col,
                    hidden: !PQGroup,
                };
            } else if (eventGroups["dtGroup"].includes(col["dataField"])) {
                return {
                    ...col,
                    hidden: !dtGroup,
                };
            } else if (eventGroups["imbalanceGroup"].includes(col["dataField"])) {
                return {
                    ...col,
                    hidden: !imbalanceGroup,
                };
            } else {
                return col;
            }
        });

        setColumnsList(cols);
    }, [columns, VIGroup, PQGroup, dtGroup, powerDropGroup, imbalanceGroup]);

    const onRowSelect = {
        onClick: (e, row, rowIndex) => {
            setTabData((prevState) => {
                return {
                    ...prevState,
                    selectedEventFile: { label: row.Timestamps, value: row.s3_bucket_key_pair },
                };
            });
        },
        onDoubleClick: () => {
            setTabData((prevState) => {
                return {
                    ...prevState,
                    showPlotOnDoubleClick: true,
                    showPlot: true,
                };
            });
        },
    };

    const outliersRowStyle = (row, rowIndex) => {
        const style = {};
        if (row.outlier === true) {
            style.backgroundColor = "#a3a3c2";
            style.fontSize = 14;
            style.width = 10;
            style.whiteSpace = "nowrap";
        } else {
            style.backgroundColor = "#ffffff";
            style.fontSize = 14;
            style.width = 10;
            style.whiteSpace = "nowrap";
        }
        return style;
    };

    return (
        <div>
            {!Array.isArray(columnsList) || !Array.isArray(rows) || columnsList.length <= 1 ? null : (
                <Card>
                    <Card.Header>
                        Events Sorting Table- {selectedEquipment ? selectedEquipment.label : null}
                    </Card.Header>

                    <Row className="eventSortTable_row">
                        <Col className="col-12 eventSorting_tableCSS">
                            <ToolkitProvider
                                keyField="id"
                                data={rows}
                                columns={columnsList}
                                exportCSV={{
                                    onlyExportFiltered: true,
                                    exportAll: false,
                                }}
                                search
                                bootstrap4
                            >
                                {(props) => (
                                    <>
                                        <div className="exportCSV_ctn">
                                            <ExportCSVButton
                                                className="event_exportBtn btn-primary"
                                                {...props.csvProps}
                                            >
                                                Export CSV
                                            </ExportCSVButton>
                                        </div>
                                        <div className="eventSearchBox_ctn">
                                            <SearchBar className="eventSearchBox" {...props.searchProps} />

                                            <ClearSearchButton
                                                className="eventSearch_clearBtn btn-primary"
                                                {...props.searchProps}
                                            />
                                        </div>
                                        <div className="groupSelector">
                                            <Form.Check
                                                checked={powerDropGroup}
                                                onChange={(val) => setPowerDropGroup(val.target.checked)}
                                                type="checkbox"
                                                label="Power Drop / Single Phasing"
                                            />
                                            <Form.Check
                                                checked={VIGroup}
                                                onChange={(val) => setVIGroup(val.target.checked)}
                                                type="checkbox"
                                                label="VI Basic Data"
                                            />
                                            <Form.Check
                                                checked={PQGroup}
                                                onChange={(val) => setPQGroup(val.target.checked)}
                                                type="checkbox"
                                                label="PQ Data"
                                            />
                                            <Form.Check
                                                checked={dtGroup}
                                                onChange={(val) => setdtGroup(val.target.checked)}
                                                type="checkbox"
                                                label="dt Data"
                                            />
                                            <Form.Check
                                                checked={imbalanceGroup}
                                                onChange={(val) => setImbalanceGroup(val.target.checked)}
                                                type="checkbox"
                                                label="Voltage Drop / Imbalance"
                                            />
                                        </div>

                                        <BootstrapTable
                                            {...props.baseProps}
                                            key="EventsSorting"
                                            noDataIndication="No Events Found"
                                            selectRow={selectRow}
                                            rowEvents={onRowSelect}
                                            rowStyle={outliersRowStyle}
                                        />
                                    </>
                                )}
                            </ToolkitProvider>
                        </Col>
                    </Row>
                </Card>
            )}
        </div>
    );
}
