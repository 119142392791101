import authAxios from "../axios/authAxios";

class AlertsAPI {
    static getActiveAlets(accessToken) {
        return authAxios.get("/v1/alerts/active/", {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static getArchiveAlerts(
        accessToken,
        start_date,
        end_date,
        customer_id,
        facility_id,
        location_node_id,
        advancedSearch
    ) {
        return authAxios.get("/v1/alerts/archive/", {
            params: {
                ...(advancedSearch && {
                    start_date: start_date,
                    end_date: end_date,
                    customer_id: customer_id,
                    facility_id: facility_id,
                    location_node_id: location_node_id,
                }),
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static getHarmonicAlerts(
        accessToken,
        start_date,
        end_date,
        customer_id,
        facility_id,
        location_node_id,
        advancedSearch
    ) {
        return authAxios.get("v1/alerts/harmonics/", {
            params: {
                ...(advancedSearch && {
                    start_date: start_date,
                    end_date: end_date,
                    customer_id: customer_id,
                    facility_id: facility_id,
                    location_node_id: location_node_id,
                }),
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static getSentAlerts(
        accessToken,
        start_date,
        end_date,
        customer_id,
        facility_id,
        location_node_id,
        advancedSearch
    ) {
        //Optionally send params if advanced search is selected
        return authAxios.get("/v1/alerts/notification/", {
            params: {
                ...(advancedSearch && {
                    start_date: start_date,
                    end_date: end_date,
                    customer_id: customer_id,
                    facility_id: facility_id,
                    location_node_id: location_node_id,
                }),
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static getAlertsContent(accessToken, key, image_keys) {
        let img_key = image_keys;
        if (image_keys === undefined) {
            img_key = "";
        }

        return authAxios.get("/v1/alerts/alert_content/", {
            params: {
                body_s3_key: `${key}`,
                img_s3_keys: `${img_key}`,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static patchNotifyCustomer(accessToken, id, falsePositive = null) {
        return authAxios.patch(
            "/v1/alerts/notify_customer/",
            {
                id: id,
            },
            {
                params: {
                    false_positive: falsePositive,
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            }
        );
    }

    static patchActiveToArchive(accessToken, id, falsePositive = null) {
        return authAxios.patch(
            "/v1/alerts/archive/",
            {
                id: id,
            },
            {
                params: {
                    false_positive: falsePositive,
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            }
        );
    }

    static patchEventTag(accessToken, id, tag) {
        return authAxios.patch(
            "/v1/alerts/tag_event/",
            {
                id: id,
            },
            {
                params: {
                    tag: tag,
                },
                headers: { Authorization: `Bearer ${accessToken}` },
            }
        );
    }

    static patchArchiveToActive(accessToken, id) {
        return authAxios.patch(
            "/v1/alerts/active/",
            {
                id: id,
            },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        );
    }

    static getAlertsTrendingPlot(accessToken, location_node_id, start_date, end_date, timezone) {
        return authAxios.get("/v1/alerts/alerts_trending/", {
            params: {
                location_node_id: `${location_node_id}`,
                start_date: `${start_date}`,
                end_date: `${end_date}`,
                timezone: `${timezone}`,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }
}

export default AlertsAPI;
