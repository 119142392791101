import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ClearButton from "./components/ClearButton/ClearButton";
import SelectCustomer from "./components/SelectCustomer/SelectCustomer";
import SelectFacility from "./components/SelectFacility/SelectFacility";
import SelectEquipment from "./components/SelectEquipment/SelectEquipmet";
import NamePlateTable from "./components/NameplateTable/NamePlateTable";
import DeploymentIssueBanner from "./components/DeploymentIssueBanner/DeploymentIssueBanner";
import FaultAlertBanner from "./components/FaultAlertBanner/FaultAlertBanner";
import EquipmentConfigsTable from "./components/EquipmentConfigsTable/EquipmentConfigsTable";
import "./LeftFilterBox.css";
import EquipmentConnStatus from "./components/EquipmentConnStatus/EquipmentConnStatus";
import EventsActiveBanner from "./components/EventsActiveBanner/EventsActiveBanner";
import EquipmentCenteredOnZeroStatus from "./components/EquipmentCenteredOnZeroStatus/EquipmentCenteredOnZeroStatus";

const LeftFilterBox = () => {
    return (
        <div>
            <Card>
                <Card.Body className="left">
                    <Row>
                        <Col>
                            <label className="select_title">Select Customer:</label>
                            <ClearButton />
                            <br></br>
                            <SelectCustomer />
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col>
                            <label className="select_title">Select Facility :</label>
                            <br></br>
                            <SelectFacility />
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col>
                            <label className="select_title">Select Equipment :</label>
                            <br></br>
                            <SelectEquipment />
                        </Col>
                    </Row>

                    <DeploymentIssueBanner />

                    <FaultAlertBanner />

                    <EquipmentConnStatus />

                    <EventsActiveBanner />

                    <EquipmentCenteredOnZeroStatus />

                    <br></br>
                    <Row className="justify-content-md-center">
                        <Col>
                            <NamePlateTable />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <EquipmentConfigsTable />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
};

export default LeftFilterBox;
