import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Cookies from "js-cookie";
import DateRangePicker from "../../../../DatePickers/DateRangePicker/DateRangePicker";
import FacilityDateRangePicker from "../../../../DatePickers/DateRangePicker/FacilityDateRangePicker";
import Select from "react-select";
import ReactLoading from "react-loading";
import Dropdown from "react-dropdown";
import TrendingAnalysisAPI from "../../../../../api/TrendingAnalysisApi";
import { AccumulatedParser } from "../../../../../parser/TrendingAnalysis";
import Form from "react-bootstrap/Form";

const trendOptions = [
    { label: "Amp Hours over NP+SF", value: "Amp Hours" },
    { label: "Starts", value: "Starts" },
];

const formatDate = (date) => {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
};

export default function AccumulatedTab({ tabData, setTabData, setPlotOrder }) {
    const token = Cookies.get("Session");

    // extracting from redux slice
    const { selectedCustomer, selectedFacility, equipmentsList, equipmentTimezone, selectedEquipment } = useSelector(
        (state) => state.equipmentsPortfolio
    );
    const selectedVoltageType = useSelector((state) => state.voltageType.selectedVoltageType);

    const [selectedNodes, setSelectedNodes] = useState([]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(new Date());

    const [selectedTrend, setSelectedTrend] = useState(trendOptions[0]);

    const [plotWithEstimationFlag, setPlotWithEstimationFlag] = useState(true);

    const [plotLoading, setPlotLoading] = useState(false);
    const [alertBanner, setAlertBanner] = useState({
        color: "",
        show: false,
        text: "",
    });

    useEffect(() => {
        if (selectedEquipment == null) {
            setSelectedNodes([]);
        } else if (selectedNodes.includes(selectedEquipment)) {
            const idx = selectedNodes.indexOf(selectedEquipment);
            setSelectedNodes([selectedEquipment, ...selectedNodes.slice(0, idx), ...selectedNodes.slice(idx + 1, 9)]);
        } else {
            setSelectedNodes([selectedEquipment, ...selectedNodes.slice(0, 9)]);
        }
    }, [selectedEquipment]);

    const updateSelectedNodes = (val) => {
        if (!val.includes(selectedEquipment)) {
            setAlertBanner({
                color: "primary",
                text: "Cannot Remove Selected Equipment From Additional Nodes List",
                show: true,
            });
            return;
        }
        setSelectedNodes(val);
    };

    const onToggleEstimationFlag = (checked) => {
        if (checked) {
            setPlotWithEstimationFlag(true);
        } else {
            setPlotWithEstimationFlag(false);
        }
    };

    useEffect(() => {
        setAlertBanner({ ...alertBanner, show: false });
    }, [selectedCustomer, selectedFacility]);

    const onPlotButtonClick = () => {
        if (selectedNodes == null || selectedNodes.length == 0) {
            //Set Alert
            setAlertBanner({
                color: "primary",
                text: "Select Equipment to Plot",
                show: true,
            });
            return null;
        } else {
            setAlertBanner({ ...alertBanner, show: false });
            setPlotLoading(true);
            setTabData((prevData) => {
                return {
                    ...prevData,
                    isPlotLoading: true,
                    showPlot: true,
                    plotData: {},
                    selectedTrend: selectedTrend.value,
                    selectedFacility: selectedFacility,
                    plotMetaData: {
                        selectedEquipment: selectedEquipment,
                        selectedNodes: selectedNodes,
                        v_type: selectedVoltageType.value,
                        startDate: formatDate(startDate),
                        endDate: formatDate(endDate),
                        timezone: equipmentTimezone.value,
                    },
                };
            });

            if (selectedTrend.value == trendOptions[0].value) {
                TrendingAnalysisAPI.getAccumulatedAmpHours(
                    token,
                    selectedNodes.map((eq) => eq.value),
                    formatDate(startDate),
                    formatDate(endDate),
                    equipmentTimezone.value
                ).then((response) => {
                    setPlotLoading(false);
                    if (response.status === 204) {
                        setAlertBanner({
                            color: "primary",
                            show: true,
                            text: "No Data Found",
                        });
                        setTabData((prevData) => {
                            return {
                                ...prevData,
                                showPlot: false,
                                isPlotLoading: false,
                            };
                        });
                    } else {
                        setTabData((prevData) => {
                            return {
                                ...prevData,
                                plotData: response.data.content.amp_over_sf_daily,
                                equipmentsList: equipmentsList,
                                showPlot: true,
                                isPlotLoading: false,
                                plotWithEstimationFlag: plotWithEstimationFlag,
                            };
                        });
                    }
                });
            } else if (selectedTrend.value == trendOptions[1].value) {
                TrendingAnalysisAPI.getAccumulatedStarts(
                    token,
                    selectedNodes.map((eq) => eq.value),
                    formatDate(startDate),
                    formatDate(endDate),
                    equipmentTimezone.value
                ).then((response) => {
                    setPlotLoading(false);
                    if (response.status === 204) {
                        setAlertBanner({
                            color: "primary",
                            show: true,
                            text: "No Data Found",
                        });
                        setTabData((prevData) => {
                            return {
                                ...prevData,
                                showPlot: false,
                                isPlotLoading: false,
                            };
                        });
                    } else {
                        setTabData((prevData) => {
                            return {
                                ...prevData,
                                plotData: response.data.content.starts_daily,
                                equipmentsList: equipmentsList,
                                showPlot: true,
                                isPlotLoading: false,
                                plotWithEstimationFlag: plotWithEstimationFlag,
                            };
                        });
                    }
                });
            }
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <h2 className="margin-top-10">Accumulated</h2>{" "}
                </Col>
            </Row>
            <Row>
                <Col className="minute-dateRange-input col-6">
                    <label>Select Date Range:</label>
                    <br></br>
                    <FacilityDateRangePicker
                        startDate={startDate}
                        endDate={endDate}
                        onStartDateChange={setStartDate}
                        onEndDateChange={setEndDate}
                    />
                </Col>
            </Row>
            <Row>
                <Col className={"col-5"}>
                    <br />
                    <label>Select Trend:</label>
                    <br></br>
                    <Dropdown options={trendOptions} value={selectedTrend} onChange={(val) => setSelectedTrend(val)} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <br />
                    <label>Select Equipment to Plot:</label>
                    <br />
                    <Select
                        options={equipmentsList}
                        isMulti
                        isClearable
                        defaultValue={selectedEquipment}
                        value={selectedNodes}
                        onChange={(val) => updateSelectedNodes(val)}
                    />
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Form.Check
                        onChange={(event) => {
                            onToggleEstimationFlag(event.target.checked);
                        }}
                        type="checkbox"
                        label="Plot with Estimated Values"
                        checked={plotWithEstimationFlag}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="col-2">
                    {plotLoading ? (
                        <ReactLoading type="bars" height={50} width={50} color="#007bff" />
                    ) : (
                        <Button variant="primary" onClick={onPlotButtonClick} className="plot_button">
                            Plot
                        </Button>
                    )}
                </Col>
                <Col className="col-5 trendingAlert">
                    <Alert
                        variant={alertBanner.color}
                        show={alertBanner.show}
                        onClose={() => setAlertBanner({ ...alertBanner, show: false })}
                        dismissible
                    >
                        {alertBanner.text}
                    </Alert>
                </Col>
            </Row>
        </React.Fragment>
    );
}
