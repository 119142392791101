import axios from '../axios/axios';
import portalAxios from '../axios/portalAxios';

class AuthAPI {
	static getAuth(jwt) {
        return axios.get('/v1/auth/',{
            params: {
                jwt: jwt,
                user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        });
	}
	
    static isLogin(token) {
        return axios.get('/v1/common/is_login/', { headers: { Authorization: `Bearer ${token}` } })
    }

    static getPortalToken(token) {
        return portalAxios.post('api/v2/sessions/analytics',
            {duration: (new Date(new Date().setHours(24,0,0)).getTime() - new Date().getTime())/ 1000},
            { headers: { Authorization: `Bearer ${token}` } })
    }
}

export default AuthAPI;
