import React, { useState } from "react";
import DatePicker from "react-datepicker";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import { useSelector } from "react-redux";

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
const tempMaxDate = Date.UTC(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate(),
    new Date().getUTCHours(),
    new Date().getUTCMinutes(),
    new Date().getUTCSeconds()
);

const DateRangePicker = ({
    startDate,
    endDate,
    minStartDate = null,
    maxEndDate = tempMaxDate,
    onStartDateChange,
    onEndDateChange,
    openOnStartDateSelect = true,
}) => {
    const [datePromptOpen, setDatePromptOpen] = useState(false);

    const equipmentStartDate = useSelector((state) => state.equipmentsPortfolio.equipmentStartDate);
    const minDate = minStartDate ?? new Date(equipmentStartDate);

    const years = range(getYear(minDate), getYear(new Date()) + 1, 1);

    return (
        <Row>
            <Col className="col-6">
                <DatePicker
                    minDate={minDate}
                    dateFormat="MMM d, yyyy"
                    maxDate={Date.UTC(
                        new Date().getUTCFullYear(),
                        new Date().getUTCMonth(),
                        new Date().getUTCDate(),
                        new Date().getUTCHours(),
                        new Date().getUTCMinutes(),
                        new Date().getUTCSeconds()
                    )}
                    todayButton="Today"
                    renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <div
                            style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                {"<"}
                            </button>
                            <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                                {years.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={months[getMonth(date)]}
                                onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                            >
                                {months.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                {">"}
                            </button>
                        </div>
                    )}
                    selected={startDate}
                    onChange={(date) => {
                        onStartDateChange(date);
                        if (openOnStartDateSelect) setDatePromptOpen(true);
                    }}
                />
            </Col>
            <Col className="col-6">
                <DatePicker
                    dateFormat="MMM d, yyyy"
                    maxDate={maxEndDate}
                    minDate={startDate}
                    open={datePromptOpen}
                    todayButton="Today"
                    renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <div
                            style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                {"<"}
                            </button>
                            <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                                {years.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={months[getMonth(date)]}
                                onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                            >
                                {months.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                {">"}
                            </button>
                        </div>
                    )}
                    onFocus={() => setDatePromptOpen(true)}
                    onClickOutside={() => setDatePromptOpen(false)}
                    selected={endDate}
                    onChange={(date) => {
                        onEndDateChange(date);
                        setDatePromptOpen(false);
                    }}
                />
            </Col>
        </Row>
    );
};

export default DateRangePicker;
