import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    portfolio: [],
    customerList: [],
    facilityList: [],
    equipmentsList: [],
    selectedCustomer: null,
    selectedFacility: null,
    selectedEquipment: null,
    equipmentTimezone: { label: "Equipment Time Zone", value: "equipment_timezone" },
    equipmentStartDate: new Date().toISOString(),
    showSELMenu: false,
    equipmentConnStatus: "ON",
    product_type: "Node",
    equipmentCenteredOnZeroStatus: {
        is_voltage_centered_on_zero: "True",
        is_current_centered_on_zero: "True",
        timestamp: "",
    },
};

const equipmentsPortfolioSlice = createSlice({
    name: "equipmentsPortfolio",
    initialState: INITIAL_STATE,
    reducers: {
        setEquipmentPortfolio(state, action) {
            const content = action.payload;
            return {
                ...state,
                portfolio: content,
                customerList: content.customers,
                facilityList: content.facilities,
                equipmentsList: content.equipments,
            };
        },
        clearEquipmentPortfolio(state) {
            return {
                ...state,
                customerList: state.portfolio.customers,
                facilityList: state.portfolio.facilities,
                equipmentsList: state.portfolio.equipments,
                selectedCustomer: null,
                selectedFacility: null,
                selectedEquipment: null,
                equipmentStartDate: new Date().toISOString(),
                showSELMenu: false,
                equipmentConnStatus: "ON",
                equipmentCenteredOnZeroStatus: {
                    is_voltage_centered_on_zero: "True",
                    is_current_centered_on_zero: "True",
                    timestamp: "",
                },
            };
        },
        setSelectedCustomer(state, action) {
            const customer = action.payload;
            return {
                ...state,
                facilityList: state.portfolio.facilities.filter((value) => {
                    return customer.label === value.customer;
                }),
                selectedCustomer: customer,
                selectedFacility: null,
                selectedEquipment: null,
                showSELMenu: false,
                equipmentConnStatus: "ON",
                equipmentCenteredOnZeroStatus: {
                    is_voltage_centered_on_zero: "True",
                    is_current_centered_on_zero: "True",
                    timestamp: "",
                },
            };
        },
        setSelectedFacility(state, action) {
            const facility = action.payload;

            let equipmentTimezone = { label: "(UTC) Coordinated Universal Time ", value: "UTC" };
            if (state.equipmentTimezone.label === "Equipment Time Zone") {
                equipmentTimezone = { label: "Equipment Time Zone", value: facility.timezone };
            }

            // filter the list based on the selected facilty and customer
            const equipmentList = state.portfolio.equipments.filter((value) => {
                return value.facility_name === facility.label && value.customer === facility.customer;
            });
            const facilityList = state.portfolio.facilities.filter((value) => {
                return equipmentList[0].customer === value.customer;
            });

            return {
                ...state,
                equipmentsList: equipmentList,
                facilityList: facilityList,
                selectedCustomer: { value: equipmentList[0].customer, label: equipmentList[0].customer },
                selectedFacility: facility,
                selectedEquipment: null,
                equipmentTimezone: equipmentTimezone,
                showSELMenu: false,
                equipmentConnStatus: "ON",
                equipmentCenteredOnZeroStatus: {
                    is_voltage_centered_on_zero: "True",
                    is_current_centered_on_zero: "True",
                    timestamp: "",
                },
            };
        },
        setSelectedEquipment(state, action) {
            const equipment = action.payload;

            let equipmentTimezone = { label: "(UTC) Coordinated Universal Time ", value: "UTC" };
            if (state.equipmentTimezone.label === "Equipment Time Zone") {
                equipmentTimezone = { label: "Equipment Time Zone", value: equipment.timezone };
            }

            // filter the list based on the selected facilty and customer
            const equipmentList = state.portfolio.equipments.filter((value) => {
                return value.facility_name === equipment.facility_name && value.customer === equipment.customer;
            });

            const facilityList = state.portfolio.facilities.filter((value) => {
                return value.customer === equipment.customer;
            });

            return {
                ...state,
                facilityList: facilityList,
                equipmentsList: equipmentList,
                selectedCustomer: { value: equipment.customer, label: equipment.customer },
                selectedFacility: {
                    value: equipment.facility_name,
                    label: equipment.facility_name,
                    facility_id: equipment.facility_id,
                    timezone: equipment.timezone,
                },
                selectedEquipment: equipment,
                equipmentTimezone: equipmentTimezone,
                equipmentStartDate: new Date(parseInt(equipment.equipment_start_epoch * 1000)).toISOString(),
                showSELMenu: equipment.product_type === "SEL" ? true : false,
                product_type: equipment.product_type,
                equipmentConnStatus: "ON",
                equipmentCenteredOnZeroStatus: {
                    is_voltage_centered_on_zero: "True",
                    is_current_centered_on_zero: "True",
                    timestamp: "",
                },
            };
        },
        setEqipmentTimezone(state, action) {
            const timezone = action.payload;
            let tempTimezone = timezone;
            if (timezone.label === "Equipment Time Zone" && state.selectedFacility) {
                tempTimezone = { label: "Equipment Time Zone", value: state.selectedFacility.timezone };
            }
            return {
                ...state,
                equipmentTimezone: tempTimezone,
            };
        },
        setEquipmentConnStatus(state, action) {
            return {
                ...state,
                equipmentConnStatus: action.payload,
            };
        },
        setEquipmentCenteredOnZeroStatus(state, action) {
            return {
                ...state,
                equipmentCenteredOnZeroStatus: action.payload,
            };
        },
    },
});

export const equipmentsPortfolioActions = equipmentsPortfolioSlice.actions;

export default equipmentsPortfolioSlice;
