import DatePicker from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import range from 'lodash/range';
import { useSelector } from 'react-redux';

const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const SingleDatePicker = (props) => {
	const { startDate, onDateChange } = props;
	const equipmentStartDate = new Date(useSelector((state) => state.equipmentsPortfolio.equipmentStartDate));

	const years = range(getYear(equipmentStartDate), getYear(new Date()) + 1, 1);

	return (
		<DatePicker
			minDate={equipmentStartDate}
			selected={startDate}
			maxDate={Date.UTC(
				new Date().getUTCFullYear(),
				new Date().getUTCMonth(),
				new Date().getUTCDate(),
				new Date().getUTCHours(),
				new Date().getUTCMinutes(),
				new Date().getUTCSeconds()
			)}
			todayButton='Today'
			renderCustomHeader={({
				date,
				changeYear,
				changeMonth,
				decreaseMonth,
				increaseMonth,
				prevMonthButtonDisabled,
				nextMonthButtonDisabled,
			}) => (
				<div
					style={{
						margin: 10,
						display: 'flex',
						justifyContent: 'center',
					}}>
					<button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
						{'<'}
					</button>
					<select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
						{years.map((option) => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
					</select>

					<select
						value={months[getMonth(date)]}
						onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
						{months.map((option) => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
					</select>

					<button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
						{'>'}
					</button>
				</div>
			)}
			onChange={(date) => {
				onDateChange(date);
			}}
			dateFormat='MMM d, yyyy'
		/>
	);
};

export default SingleDatePicker;
