class TableDataParser {
    static voltageHATReport(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "harmonic_lf", text: "Harmonic LF", sort: true },
            { dataField: "lt_avg", text: "LT Avg", sort: true },
            { dataField: "st_avg", text: "ST Avg", sort: true },
            { dataField: "change", text: "Change", sort: true },
            { dataField: "impact", text: "Impact", sort: true },
            { dataField: "lt_count", text: "LT Count", sort: true },
            { dataField: "st_count", text: "ST Count", sort: true },
            { dataField: "total_count", text: "Total Count", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        const rows = reportData.new_harmonics;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
            rows[row].plot_type = "voltage_harmonics";
            rows[row].harmonic_type = "Voltage";
        }
        const title = "Voltage HAT Report:";
        const description = "";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static voltageHATReportV2(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "starter", text: "Starter Type", sort: true },
            { dataField: "harmonic_lf", text: "Harmonic LF", sort: true },
            { dataField: "lt_avg", text: "LT Avg", sort: true },
            { dataField: "st_avg", text: "ST Avg", sort: true },
            { dataField: "change", text: "Change", sort: true },
            { dataField: "lt_count", text: "LT Count", sort: true },
            { dataField: "st_count", text: "ST Count", sort: true },
            { dataField: "total_count", text: "Total Count", sort: true },
            { dataField: "lt_harmonic_max_lf_value", text: "Max Peak LF", sort: true },
            { dataField: "lt_harmonic_max_lf_value_date", text: "Max Peak LF Date", sort: true },
            { dataField: "lt_harmonic_min_lf_value", text: "Min Peak LF", sort: true },
            { dataField: "lt_harmonic_min_lf_value_date", text: "Min Peak LF Date", sort: true },
            { dataField: "scan_period_type", text: "Scan Period Type", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        const rows = reportData.new_harmonics;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
            rows[row].plot_type = "voltage_harmonics";
            rows[row].harmonic_type = "Voltage";
        }
        const title = "Voltage HAT Report:";
        const description = "";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static voltageHATReportV2AbvThreshold(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "starter", text: "Starter Type", sort: true },
            { dataField: "harmonic_lf", text: "Harmonic LF", sort: true },
            { dataField: "lt_avg", text: "LT Avg", sort: true },
            { dataField: "st_avg", text: "ST Avg", sort: true },
            { dataField: "change", text: "Change", sort: true },
            { dataField: "lt_count", text: "LT Count", sort: true },
            { dataField: "st_count", text: "ST Count", sort: true },
            { dataField: "total_count", text: "Total Count", sort: true },
            { dataField: "lt_harmonic_max_lf_value", text: "Max Peak LF", sort: true },
            { dataField: "lt_harmonic_max_lf_value_date", text: "Max Peak LF Date", sort: true },
            { dataField: "lt_harmonic_min_lf_value", text: "Min Peak LF", sort: true },
            { dataField: "lt_harmonic_min_lf_value_date", text: "Min Peak LF Date", sort: true },
            { dataField: "scan_period_type", text: "Scan Period Type", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        const rows = reportData.harmonics_abv_threshold;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
            rows[row].plot_type = "voltage_harmonics";
            rows[row].harmonic_type = "Voltage";
        }
        const title = "Voltage HAT Report Above 10% Avg:";
        const description = "";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static currentHATReport(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "harmonic_lf", text: "Harmonic LF", sort: true },
            { dataField: "lt_avg", text: "LT Avg", sort: true },
            { dataField: "st_avg", text: "ST Avg", sort: true },
            { dataField: "change", text: "Change", sort: true },
            { dataField: "impact", text: "Impact", sort: true },
            { dataField: "lt_count", text: "LT Count", sort: true },
            { dataField: "st_count", text: "ST Count", sort: true },
            { dataField: "total_count", text: "Total Count", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        const rows = reportData.harmonics_abv_threshold;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
            rows[row].plot_type = "current_harmonics";
            rows[row].harmonic_type = "Current";
        }
        const title = "Current HAT Report:";
        const description = "";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static currentHATReportV2(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "starter", text: "Starter Type", sort: true },
            { dataField: "harmonic_lf", text: "Harmonic LF", sort: true },
            { dataField: "lt_avg", text: "LT Avg", sort: true },
            { dataField: "st_avg", text: "ST Avg", sort: true },
            { dataField: "change", text: "Change", sort: true },
            { dataField: "lt_count", text: "LT Count", sort: true },
            { dataField: "st_count", text: "ST Count", sort: true },
            { dataField: "total_count", text: "Total Count", sort: true },
            { dataField: "lt_harmonic_max_lf_value", text: "Max Peak LF", sort: true },
            { dataField: "lt_harmonic_max_lf_value_date", text: "Max Peak LF Date", sort: true },
            { dataField: "lt_harmonic_min_lf_value", text: "Min Peak LF", sort: true },
            { dataField: "lt_harmonic_min_lf_value_date", text: "Min Peak LF Date", sort: true },
            { dataField: "scan_period_type", text: "Scan Period Type", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];
        const rows = reportData.report_harmonics;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
            rows[row].plot_type = "current_harmonics";
            rows[row].harmonic_type = "Current";
        }
        const title = "Current HAT Report:";
        const description = "";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static currentHATReportV2AbvThreshold(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "starter", text: "Starter Type", sort: true },
            { dataField: "harmonic_lf", text: "Harmonic LF", sort: true },
            { dataField: "lt_avg", text: "LT Avg", sort: true },
            { dataField: "st_avg", text: "ST Avg", sort: true },
            { dataField: "change", text: "Change", sort: true },
            { dataField: "lt_count", text: "LT Count", sort: true },
            { dataField: "st_count", text: "ST Count", sort: true },
            { dataField: "total_count", text: "Total Count", sort: true },
            { dataField: "lt_harmonic_max_lf_value", text: "Max Peak LF", sort: true },
            { dataField: "lt_harmonic_max_lf_value_date", text: "Max Peak LF Date", sort: true },
            { dataField: "lt_harmonic_min_lf_value", text: "Min Peak LF", sort: true },
            { dataField: "lt_harmonic_min_lf_value_date", text: "Min Peak LF Date", sort: true },
            { dataField: "scan_period_type", text: "Scan Period Type", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];
        const rows = reportData.harmonics_abv_threshold;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
            rows[row].plot_type = "current_harmonics";
            rows[row].harmonic_type = "Current";
        }
        const title = "Current HAT Report Above 10% Avg:";
        const description = "";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static trendingReport(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "parameter_label", text: "Parameter", sort: true },
            { dataField: "lt_avg", text: "LT Avg", sort: true },
            { dataField: "st_avg", text: "ST Avg", sort: true },
            { dataField: "change", text: "% Change", sort: true },
            //{ dataField: 'threshold', text: 'Threshold' , sort:true},  //This column is now handled below due to modification and addition of column in new reports
            { dataField: "customer_name", text: "Customer", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        if (reportData.length > 0) {
            if (Object.keys(reportData[0]).includes("threshold")) {
                columns.splice(7, 0, {
                    dataField: "threshold",
                    text: "% Change Threshold",
                    sort: true,
                    falign: "left",
                });
            } else if (
                Object.keys(reportData[0]).includes("change_threshold") &&
                Object.keys(reportData[0]).includes("absolute_threshold")
            ) {
                columns.splice(
                    7,
                    0,
                    { dataField: "change_threshold", text: "% Change Threshold", sort: true },
                    { dataField: "absolute_threshold", text: "Absolute Threshold", sort: true }
                );
            }
        } else {
            //Default to new table when data is empty
            columns.splice(
                7,
                0,
                { dataField: "change_threshold", text: "% Change Threshold", sort: true },
                { dataField: "absolute_threshold", text: "Absolute Threshold", sort: true }
            );
        }

        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
            rows[row].plot_type = "trending";
            const param = rows[row].parameter.split("_");
            if (param[1] === "thd" || param[1] === "gis") {
                rows[row].parameter_label =
                    param[0].charAt(0).toUpperCase() + param[0].slice(1) + " " + param[1].toUpperCase();
            } else if (typeof param[1] !== "undefined") {
                rows[row].parameter_label =
                    param[0].charAt(0).toUpperCase() +
                    param[0].slice(1) +
                    " " +
                    param[1].charAt(0).toUpperCase() +
                    param[1].slice(1);
            } else {
                rows[row].parameter_label = param[0].charAt(0).toUpperCase() + param[0].slice(1);
            }

            rows[row].plot_params = [
                { label: "Voltage", value: "Voltage" },
                { label: "Current", value: "Current" },
                { label: "Line Frequency", value: "Line Frequency" },
            ];

            // Skipping Voltage and current from the list

            if (
                rows[row].parameter_label.localeCompare("Current") !== 0 &&
                rows[row].parameter_label.localeCompare("Voltage") !== 0
            ) {
                rows[row].plot_params.push({ label: rows[row].parameter_label, value: rows[row].parameter_label });
            }
        }

        const title = "Trending:";
        const description = "";

        return { columns: columns, rows: rows, title: title };
    }

    static voltageImbalanceReport(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "st_avg", text: "ST Avg", sort: true },
            { dataField: "absolute_threshold", text: "Absolute Threshold", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
            rows[row].plot_type = "trending";

            rows[row].plot_params = [
                { label: "Voltage", value: "Voltage" },
                { label: "Voltage Imbalance", value: "Voltage Imbalance" },
                { label: "Current", value: "Current" },
                { label: "Line Frequency", value: "Line Frequency" },
            ];
        }

        const title = "Voltage Imbalance L-L:";
        const description = "Table with all nodes where short term voltage imbalance is above the threshold.";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static eventsTrendingTable(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "event_type", text: "Event Type", sort: true },
            { dataField: "lt_avg_count", text: "LT Avg", sort: true },
            { dataField: "st_avg_count", text: "ST Count", sort: true },
            { dataField: "change", text: "% Change", sort: true },
            { dataField: "threshold", text: "Threshold", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
            rows[row].plot_type = "events_count";
        }

        const title = "Events Trending Table:";
        const description = "";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static outliarEventsTable(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "event_type", text: "Event Type", sort: true },
            { dataField: "outlier_type", text: "Outlier Type", sort: true },
            { dataField: "event_utc_timestamp", text: "UTC Timestamp", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
            rows[row].plot_type = "outlier_event";
            if (
                rows[row].event_type === "start" ||
                rows[row].event_type === "stop" ||
                rows[row].event_type === "start-d" ||
                rows[row].event_type === "stop-u"
            ) {
                rows[row].event_param_type = "Start-Stop";
            } else {
                rows[row].event_param_type = "Transient";
            }
        }

        const title = "Outlier Events:";
        const description = "";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static noSSTable(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "recent_start", text: "Recent Start", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
        }

        const title = "No Start-Stops in last 14 Days:";
        const description = "";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static noStartTable(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "recent_start", text: "Recent Start", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
        }

        const title = "No Start in last 14 Days:";
        const description = "";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static noStopTable(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "recent_stop", text: "Recent Stop", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
        }

        const title = "No Stop in last 14 Days:";
        const description = "";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static noTransientsTable(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
        }

        const title = "No Transients in last 14 Days:";
        const description = "";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static noVoltageTransientsTable(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
        }

        const title = "No Voltage Transients in last 14 Days:";
        const description = "";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static noCurrentTransientsTable(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
        }

        const title = "No Current Transients in last 14 Days:";
        const description = "";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static noVoltageRisesTable(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
        }

        const title = "No Voltage Rises in last 14 Days:";
        const description = "";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static noVoltageFallsTable(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
        }

        const title = "No Voltage Falls in last 14 Days:";
        const description = "";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static noCurrentRisesTable(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
        }

        const title = "No Current Rises in last 14 Days:";
        const description = "";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static noCurrentFallsTable(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
        }

        const title = "No Current Falls in last 14 Days:";
        const description = "";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static harmonicMappingTable(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "harmonic_lf", text: "Harmonic LF", sort: true },
            { dataField: "signature_name", text: "Harmonic Signature", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "id", text: "id", sort: true, hidden: true },
        ];

        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
            rows[row].harmonic_lf = rows[row].harmonic_lf.join(", ");
        }

        const title = "Harmonics Mapping Report";
        const description = "";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static deploymentIssueReport(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "new_deployment_status", text: "Deployment Status", sort: true },
            { dataField: "timestamp", text: "Deployment Status Last Update", sort: true },
            { dataField: "disable_monitoring", text: "Disable Monitoring", sort: true },
            { dataField: "deployment_issue", text: "Deployment Issue", sort: true },
            { dataField: "notify_on_connect", text: "Notify On Next Connect", sort: true },
            { dataField: "notify_on_current_above_noise", text: "Notify On Current Abv Noise", sort: true },
            { dataField: "show_raw_power", text: "Show Raw Power", sort: true },
            { dataField: "pause_notifications", text: "Pause Analytics Notifications", sort: true },
            { dataField: "pause_connectivity_notifications", text: "Pause Connectivity Notifications", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
        }
        const title = "Deployment Flags:";
        const description =
            `This table is a list of any node that meets the following conditions:\n` +
            `\tDeployed Nodes:\n` +
            `\t\t- deployment_issue\n\t\t- notify_on_connect\n\t\t- notify_on_current_above_noise\n` +
            `\t\t- show_raw_power\n\t\t- pause_notifications\n\t\t- disable_monitoring\n\t\t- pause_connectivity_notifications\n\n` +
            `\tPre-Deployed Nodes:\n` +
            `\t\t- deployment_issue\n\t\t- show_raw_power\n\t\t- pause_notifications\n\t\t- pause_connectivity_notifications`;

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static notCenteredOnZeroTable(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "file_timestamp", text: "Timestamp", sort: true },
            { dataField: "v_noise", text: "Voltage Threshold", sort: true },
            { dataField: "avg_voltage", text: "Average Voltage", sort: true },
            { dataField: "i_noise", text: "Current Threshold", sort: true },
            { dataField: "avg_current", text: "Average Current", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
            { dataField: "s3_location", text: "s3_location", sort: true, hidden: true },
        ];

        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
            rows[row].file_timestamp = rows[row].file_timestamp + " UTC";
        }
        const title = "Not Centered on Zero:";
        const description =
            "Table of all nodes with a phase from the its most recent ON esa file who's absolute average is greater than the noise threshold";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static channelMappingTable(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "file_timestamp", text: "Timestamp", sort: true },
            { dataField: "channel_map", text: "Channel Mapping", sort: true },
            { dataField: "a_phase_deg", text: "θA", sort: true },
            { dataField: "b_phase_deg", text: "θB", sort: true },
            { dataField: "c_phase_deg", text: "θC", sort: true },
            { dataField: "vfd_driven", text: "VFD", sort: true },
            { dataField: "work_cycle", text: " Work Cycle", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
            { dataField: "s3_location", text: "s3_location", sort: true, hidden: true },
            { dataField: "va_crossings", text: "VA X", sort: true, hidden: true },
            { dataField: "vb_crossings", text: "VB X", sort: true, hidden: true },
            { dataField: "vc_crossings", text: "VC X", sort: true, hidden: true },
            { dataField: "ia_crossings", text: "IA X", sort: true, hidden: true },
            { dataField: "ib_crossings", text: "IB X", sort: true, hidden: true },
            { dataField: "ic_crossings", text: "IC X", sort: true, hidden: true },
            { dataField: "a_avg_crossings_modia", text: "Phase A - Crossings Difference", sort: true, hidden: true },
            { dataField: "b_avg_crossings_modib", text: "Phase B - Crossings Difference", sort: true, hidden: true },
            { dataField: "c_avg_crossings_modic", text: "Phase C - Crossings Difference", sort: true, hidden: true },
            { dataField: "ia_avg_period", text: "Ia Avg Period", sort: true, hidden: true },
            { dataField: "ib_avg_period", text: "Ib Avg Period", sort: true, hidden: true },
            { dataField: "ic_avg_period", text: "Ic Avg Period", sort: true, hidden: true },
            { dataField: "flag_a", text: "Flag A", sort: true, hidden: true },
            { dataField: "flag_b", text: "Flag B", sort: true, hidden: true },
            { dataField: "flag_c", text: "Flag C", sort: true, hidden: true },
        ];
        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
            rows[row].file_timestamp = rows[row].file_timestamp + " UTC";
            rows[row].a_phase_deg = rows[row].a_phase_deg.toFixed(3);
            rows[row].b_phase_deg = rows[row].b_phase_deg.toFixed(3);
            rows[row].c_phase_deg = rows[row].c_phase_deg.toFixed(3);
        }
        const title = "Channel Mapping:";
        const description =
            "Table of all nodes with a phase from its most recent esa ON file that contains a phase(s) with a difference in zero-crossings between voltage" +
            " and current phases that are greater than 1/4 period. This means if current is leading or lagging by greater than 90 degrees.";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static missingChannelTable(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "missing_channels", text: "Missing Channels", sort: true },
            { dataField: "max_days", text: "Days", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
            { dataField: "s3_location", text: "s3_location", sort: true, hidden: true },
            { dataField: "missing_current_count", text: "Missing Current Channels", sort: true, hidden: true },
            { dataField: "missing_voltage_count", text: "Missing Voltage Channels", sort: true, hidden: true },
        ];

        const rows = reportData;
        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
            rows[row].file_timestamp = rows[row].file_timestamp + " UTC";
        }
        const title = "Missing Channels";
        const description =
            "Table of all nodes with a missing channel. Nodes with a phase less than the noise setting or with a phase less than 0.3x the highest phase qualify";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static alertMappingReport(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true, classes: "sticky-table-col-cell" },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "pause_notifications", text: "Pause Analytics Notifications", sort: true },
            { dataField: "alerts_set_to_false", text: "Alerts Set to False", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];
        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
        }

        const title = "Alert Mapping";
        const description = "";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static connectionTable(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "connection_status", text: "Status", sort: true },
            { dataField: "last_connected_at", text: "Last Connected", sort: true },
            { dataField: "days_since_last_connected", text: "Days", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
            { dataField: "s3_location", text: "s3_location", sort: true, hidden: true },
            { dataField: "missing_current_count", text: "Missing Current Channels", sort: true, hidden: true },
            { dataField: "missing_voltage_count", text: "Missing Voltage Channels", sort: true, hidden: true },
        ];

        const rows = reportData;
        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
            rows[row].file_timestamp = rows[row].file_timestamp + " UTC";
        }
        const title = "Connection Report";
        const description =
            "Table of all the nodes that are not currently cloud connected. The date the equipment was last connected at is listed or the equipment start date if it has never been connected.";

        return { columns: columns, rows: rows, title: title, description: description };
    }

    static deploymentStatusTable(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "new_deployment_status", text: "Deployment Status", sort: true },
            { dataField: "timestamp", text: "Deployment Status Last Update", sort: true },
            { dataField: "days_since_status_update", text: "Days Since Update", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
        }
        const title = "Pre-Deployment Status:";
        const description =
            "This table lists all non-Deployed Nodes and the date when their deployment status was last updated.";

        return { columns: columns, rows: rows, title: title, description: description };
    }
    static lateEventsTable(reportData) {
        const columns = [
            { dataField: "node_sn", text: "Node Serial", sort: true },
            { dataField: "location_name", text: "Equipment", sort: true },
            { dataField: "customer_name", text: "Customer", sort: true },
            { dataField: "facility_name", text: "Facility", sort: true },
            { dataField: "time", text: "Event Time", sort: true },
            { dataField: "file_landing_time", text: "File Landing Time", sort: true },
            { dataField: "hours_late", text: "# of Hours Late", sort: true },

            // Hidden Colums
            { dataField: "location_node_id", text: "location_node_id", sort: true, hidden: true },
            { dataField: "facility_id", text: "facility_id", sort: true, hidden: true },
            { dataField: "customer_id", text: "customer_id", sort: true, hidden: true },
        ];

        const rows = reportData;

        // appending unique id to data
        for (const row in rows) {
            rows[row].id = Date.now() * Math.random() * parseInt(rows[row].node_sn);
        }
        const title = "Late Events:";
        const description =
            "This table lists all events that came in to the landing zone greater than an hour after the recorded time of the event.";

        console.log(rows);

        return { columns: columns, rows: rows, title: title, description: description };
    }
}

export default TableDataParser;
