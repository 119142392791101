import axios from 'axios';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode'


const authAxios = axios.create({
    baseURL: process.env.REACT_APP_ECS_API_URL,
});

// this runs before api call
authAxios.interceptors.request.use(request => {
    // Checking token is expired
	const token = request.headers.Authorization
	let tokenDuration = new Date().getTime();
	if (token !== undefined) {
		tokenDuration = new Date(jwt_decode(token.split(' ')[1]).exp * 1000).getTime();
	}
	if (new Date().getTime() >= tokenDuration) {
		Cookies.remove('Session');
		window.location = process.env.REACT_APP_PORTAL_URL + '/#/logout';
	}
	return request
})

export default authAxios;
