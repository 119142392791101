import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";

import "./EquipmentConfigsTable.css";

const columns = [
    { dataField: "Configs", text: "Configs" },
    { dataField: "Ratings", text: "Ratings" },
];

const EquipmentConfigsTable = () => {
    const [isCollapseOpen, setIsCollapseOpen] = useState(false);

    const selectedEquipment = useSelector((state) => state.equipmentsPortfolio.selectedEquipment);

    let rows = [];

    if (selectedEquipment) {
        rows = [
            { id: 0, Configs: "I Noise", Ratings: selectedEquipment.i_noise },
            { id: 1, Configs: "V Noise", Ratings: selectedEquipment.v_noise },
            { id: 2, Configs: "Overcurrent Threshold", Ratings: selectedEquipment.over_i_threshold },
            { id: 3, Configs: "Overvoltage Threshold", Ratings: selectedEquipment.over_v_threshold },
            { id: 4, Configs: "Undervoltage Threshold", Ratings: selectedEquipment.under_v_threshold },
            { id: 5, Configs: "Current Imabalance Threshold", Ratings: selectedEquipment.i_imb_threshold },
            { id: 6, Configs: "Voltage Imbalance Threshold", Ratings: selectedEquipment.v_imb_threshold },
            { id: 7, Configs: "Current THD Threshold", Ratings: selectedEquipment.i_thd_threshold },
            { id: 8, Configs: "Voltage THD Threshold", Ratings: selectedEquipment.v_thd_threshold },
            { id: 9, Configs: "TR I MAX", Ratings: selectedEquipment.tr_i_max },
            { id: 10, Configs: "Work Cycle", Ratings: selectedEquipment.work_cycle },
            { id: 11, Configs: "Events Active", Ratings: selectedEquipment.events_active ? 1 : 0 },
            { id: 12, Configs: "Eq Type", Ratings: selectedEquipment.eq_type },
            { id: 13, Configs: "Eq Type Sub", Ratings: selectedEquipment.eq_type_sub },
        ];
    }

    const onConfigButtonClick = () => {
        setIsCollapseOpen(!isCollapseOpen);
    };

    return (
        <div className="configs-table">
            {selectedEquipment ? (
                <>
                    <Button onClick={onConfigButtonClick}>Configs and Thresholds</Button>

                    <Collapse in={isCollapseOpen}>
                        <div>
                            <br></br>
                            <BootstrapTable keyField="id" data={rows} columns={columns} />
                        </div>
                    </Collapse>
                </>
            ) : null}
        </div>
    );
};

export default EquipmentConfigsTable;
