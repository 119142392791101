const createDownloadCSV = (csvData, csvFileName) => {
	const link = document.createElement('a');
	link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(csvData)}`);
	link.setAttribute('download', csvFileName);

	link.style.display = 'none';
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export default createDownloadCSV;
