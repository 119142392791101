import authAxios from "../axios/authAxios";

class CommonAPI {
    static getCustomerPortfolio(accessToken) {
        return authAxios.get("/v1/common/customer_portfolio/", { headers: { Authorization: `Bearer ${accessToken}` } });
    }
    static reportError(accessToken, error) {
        return authAxios.post(
            "/v2/log_error/",
            {
                message: error,
            },
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            }
        );
    }
}

export default CommonAPI;
