import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import DateRangePicker from "../../../../DatePickers/DateRangePicker/DateRangePicker";
import EventsAnalysisAPI from "../../../../../api/EventsAnalysisApi";
import Dropdown from "react-dropdown";
import Cookies from "js-cookie";
import ReactLoading from "react-loading";

const freqCalculationOptions = [
    {
        label: "None",
        value: "None",
    },
    {
        label: "ZC Calculation",
        value: "zc_calculation",
    },
    {
        label: "dVdT Calculation",
        value: "snar",
    },
];

const formatDate = (date) => {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
};

export default function EventsCapture({ tabData, setTabData, setPlotsOrder }) {
    const token = Cookies.get("Session");

    // extarcting from redux slice
    const { selectedCustomer, selectedFacility, selectedEquipment, equipmentTimezone, showSELMenu } = useSelector(
        (state) => state.equipmentsPortfolio
    );
    const selectedVoltageType = useSelector((state) => state.voltageType.selectedVoltageType);

    useEffect(() => {
        if (tabData.showPlotOnDoubleClick) {
            onEventCapturePlotButtonClick();
        }
        setTabData((prevState) => {
            return {
                ...prevState,
                showPlotOnDoubleClick: false,
            };
        });
    }, [tabData.showPlotOnDoubleClick]);

    // Events Capture Tab Hooks
    const [eventCaptureStartDate, setEventCaptureStartDate] = useState(
        new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 7)
    );
    const [eventCaptureEndDate, setEventCaptureEndDate] = useState(new Date());

    const [selectedEventType, setSelectedEventType] = useState("Start - Stop");
    const [eventFileList, setEventFileList] = useState([]);
    const [selectedFreqCalculationType, setSelectedFreqCalculationType] = useState(freqCalculationOptions[0]);
    const [currentFormatedDateTime, SetCurrentFormatedDateTime] = useState("");

    const [movingAvgLength, setMovingAvgLength] = useState(1);
    const [isMovingAvgLengthInvalid, setIsMovingAvgLengthInvalid] = useState(false);

    const [eventsCaptureAlert, setEventsCaptureAlert] = useState({
        color: "blue",
        text: "",
        show: false,
    });
    const [isEventsCapturePlotLoading, setIsEventsCapturePlotLoading] = useState(false);

    const onMovingAvgLengthChange = (newLength) => {
        //Add time delay for set / replot
        const length = parseFloat(newLength);
        setMovingAvgLength(length);
        if (length >= 1 && length <= 1024) {
            setIsMovingAvgLengthInvalid(false);
        } else {
            setIsMovingAvgLengthInvalid(true);
        }
    };

    const formatDateFororrelatingTab = (dateTimeStr) => {
        if (!dateTimeStr) {
            return "";
        }
        const [dateStr] = dateTimeStr.split(" ");
        const [year, month, day] = dateStr.split("-");
        const [timeStr] = dateTimeStr.split(" ").slice(1);
        const fulleTime = timeStr.split(".")[0];
        SetCurrentFormatedDateTime(`${year}-${parseInt(month, 10)}-${parseInt(day, 10)} ${fulleTime}`);
        setTabData((prevData) => {
            return {
                ...prevData,
                currentSelectedTimeOnEventsCaptureTab: `${year}-${parseInt(month, 10)}-${parseInt(
                    day,
                    10
                )} ${fulleTime}`,
            };
        });
    };

    useEffect(() => {
        setEventFileList([]);
        setEventsCaptureAlert({ ...eventsCaptureAlert, show: false });
        setTabData((prevData) => {
            return {
                ...prevData,
                selectedEventFile: "",
                eventsTableData: {
                    rows: [],
                    columns: [],
                },
            };
        });
    }, [selectedCustomer, selectedFacility, selectedEquipment]);

    useEffect(() => {
        if (tabData.selectedEventFile.label) {
            const inputDateString = tabData.selectedEventFile.label;
            const parts = inputDateString.split(/[- :]/);
            const year = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10) - 1;
            const day = parseInt(parts[2], 10);
            const hour = parseInt(parts[3], 10);
            const minute = parseInt(parts[4], 10);
            const second = parseInt(parts[5], 10);
            const outputDate = new Date(year, month, day, hour, minute, second);
            SetCurrentFormatedDateTime(outputDate);
            setTabData((prevData) => {
                return {
                    ...prevData,
                    currentSelectedTimeOnEventsCaptureTab: outputDate,
                };
            });
            return;
        }
    }, [tabData.selectedEventFile]);

    useEffect(() => {
        if (selectedEquipment) {
            const equipStartDate = new Date(parseInt(selectedEquipment.equipment_start_epoch * 1000));

            // Reset Date Pickers
            if (equipStartDate > eventCaptureStartDate) {
                const tempDate = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 7);

                if (equipStartDate > tempDate) setEventCaptureStartDate(equipStartDate);
                else setEventCaptureStartDate(tempDate);

                setEventCaptureEndDate(new Date());
            }

            EventsAnalysisAPI.get_events_data(
                token,
                selectedEquipment.value,
                formatDate(eventCaptureStartDate),
                formatDate(eventCaptureEndDate),
                equipmentTimezone.value,
                selectedEquipment.np_current,
                true,
                selectedEquipment.product_type,
                selectedVoltageType.value
            )
                .then((response) => {
                    if (response.status === 204) {
                        setEventsCaptureAlert({
                            color: "primary",
                            text: "No Event Found",
                            show: true,
                        });
                        setTabData((prevData) => {
                            return {
                                ...prevData,
                                eventsTableData: {
                                    rows: [],
                                    columns: [],
                                },
                            };
                        });
                    } else {
                        if (response.data.content == null) {
                            const text = response.data.info;
                            setEventsCaptureAlert({
                                color: "primary",
                                text: text,
                                show: true,
                            });
                        } else {
                            setEventFileList(response.data.content.list_data);
                            setTabData((prevData) => {
                                return {
                                    ...prevData,
                                    eventsTableData: {
                                        rows: response.data.content.table_data.rows,
                                        columns: response.data.content.table_data.columns,
                                    },
                                };
                            });
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [selectedEquipment]);

    {
        /************************************ Event Capture Tab fuctions **************************************************************/
    }

    useEffect(() => {
        setEventsCaptureAlert({
            color: "primary",
            text: "",
            show: false,
        });
        if (selectedEquipment) {
            setTabData((prevData) => {
                return {
                    ...prevData,
                    selectedEventFile: "",
                    eventsTableData: {
                        rows: [],
                        columns: [],
                    },
                };
            });
            EventsAnalysisAPI.get_events_data(
                token,
                selectedEquipment.value,
                formatDate(eventCaptureStartDate),
                formatDate(eventCaptureEndDate),
                equipmentTimezone.value,
                selectedEquipment.np_current,
                true,
                selectedEquipment.product_type,
                selectedVoltageType.value
            )
                .then((response) => {
                    if (response.status === 204) {
                        setEventsCaptureAlert({
                            color: "primary",
                            text: "No Event Found",
                            show: true,
                        });
                        setTabData((prevData) => {
                            return {
                                ...prevData,
                                eventsTableData: {
                                    rows: [],
                                    columns: [],
                                },
                            };
                        });
                    } else {
                        if (response.data.content == null) {
                            const text = response.data.info;
                            setEventsCaptureAlert({
                                color: "primary",
                                text: text,
                                show: true,
                            });
                        } else {
                            setEventFileList(response.data.content.list_data);
                            setTabData((prevData) => {
                                return {
                                    ...prevData,
                                    eventsTableData: {
                                        rows: response.data.content.table_data.rows,
                                        columns: response.data.content.table_data.columns,
                                    },
                                };
                            });
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [equipmentTimezone]);

    useEffect(() => {
        if (tabData.showPlot && tabData.selectedEventFile) {
            setEventsCaptureAlert({ ...eventsCaptureAlert, show: false });
            setIsEventsCapturePlotLoading(true);
            setTabData((prevData) => {
                return {
                    ...prevData,
                    plotMetaData: {
                        ...prevData.plotMetaData,
                        selectedVoltageType: selectedVoltageType,
                    },
                    isEventsCapturePlotLoading: true,
                };
            });
            EventsAnalysisAPI.event_capture_plot_data(
                token,
                tabData.selectedEventFile.value,
                selectedEquipment.location_node_id,
                selectedFreqCalculationType.value,
                selectedEquipment.product_type,
                selectedVoltageType.value,
                movingAvgLength
            )
                .then((response) => {
                    if (response.status === 200) {
                        setTabData((prevData) => {
                            return {
                                ...prevData,
                                plotData: response.data.content.plot_data,
                                showPlot: true,
                                isEventsCapturePlotLoading: false,
                            };
                        });

                        setPlotsOrder({
                            eventsCapture: 1,
                            correlatingEvents: 2,
                            eventsTrending: 3,
                            eventsThreshold: 4,
                            eventsFeaturesPlot: 5,
                            selEventsTrendingPlot: 7,
                        });
                    }
                    setIsEventsCapturePlotLoading(false);
                })
                .catch((err) => {
                    setTabData((prevData) => {
                        return {
                            ...prevData,
                            isEventsCapturePlotLoading: false,
                        };
                    });
                    setIsEventsCapturePlotLoading(false);
                    console.log(err);
                });
        }
        if (selectedEquipment) {
            EventsAnalysisAPI.get_events_data(
                token,
                selectedEquipment.value,
                formatDate(eventCaptureStartDate),
                formatDate(eventCaptureEndDate),
                equipmentTimezone.value,
                selectedEquipment.np_current,
                true,
                selectedEquipment.product_type,
                selectedVoltageType.value
            )
                .then((response) => {
                    if (response.status === 204) {
                        setEventsCaptureAlert({
                            color: "primary",
                            text: "No Event Found",
                            show: true,
                        });
                        setTabData((prevData) => {
                            return {
                                ...prevData,
                                eventsTableData: {
                                    rows: [],
                                    columns: [],
                                },
                            };
                        });
                    } else {
                        if (response.data.content == null) {
                            const text = response.data.info;
                            setEventsCaptureAlert({
                                color: "primary",
                                text: text,
                                show: true,
                            });
                        } else {
                            setEventFileList(response.data.content.list_data);
                            setTabData((prevData) => {
                                return {
                                    ...prevData,
                                    eventsTableData: {
                                        rows: response.data.content.table_data.rows,
                                        columns: response.data.content.table_data.columns,
                                    },
                                };
                            });
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [selectedVoltageType]);

    const onEventCaptureStartDateChange = (date) => {
        setEventFileList([]);
        setEventCaptureStartDate(date);
        setTabData((prevData) => {
            return {
                ...prevData,
                selectedEventFile: "",
                eventsTableData: {
                    rows: [],
                    columns: [],
                },
            };
        });
        setEventsCaptureAlert({
            color: "primary",
            text: "",
            show: false,
        });

        if (selectedEquipment) {
            EventsAnalysisAPI.get_events_data(
                token,
                selectedEquipment.value,
                formatDate(date),
                formatDate(eventCaptureEndDate),
                equipmentTimezone.value,
                selectedEquipment.np_current,
                true,
                selectedEquipment.product_type,
                selectedVoltageType.value
            )
                .then((response) => {
                    if (response.status === 204) {
                        setEventsCaptureAlert({
                            color: "primary",
                            text: "No Event Found",
                            show: true,
                        });
                        setTabData((prevData) => {
                            return {
                                ...prevData,
                                eventsTableData: {
                                    rows: [],
                                    columns: [],
                                },
                            };
                        });
                    } else {
                        if (response.data.content == null) {
                            const text = response.data.info;
                            setEventsCaptureAlert({
                                color: "primary",
                                text: text,
                                show: true,
                            });
                        } else {
                            setEventFileList(response.data.content.list_data);
                            setTabData((prevData) => {
                                return {
                                    ...prevData,
                                    eventsTableData: {
                                        rows: response.data.content.table_data.rows,
                                        columns: response.data.content.table_data.columns,
                                    },
                                };
                            });
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const onEventCaptureEndDateChange = (date) => {
        setEventFileList([]);
        setTabData((prevData) => {
            return {
                ...prevData,
                selectedEventFile: "",
                eventsTableData: {
                    rows: [],
                    columns: [],
                },
            };
        });

        setEventsCaptureAlert({
            color: "primary",
            text: "No Event Found",
            show: false,
        });
        setEventCaptureEndDate(date);
        if (selectedEquipment) {
            EventsAnalysisAPI.get_events_data(
                token,
                selectedEquipment.value,
                formatDate(eventCaptureStartDate),
                formatDate(date),
                equipmentTimezone.value,
                selectedEquipment.np_current,
                true,
                selectedEquipment.product_type,
                selectedVoltageType.value
            )
                .then((response) => {
                    if (response.status === 204) {
                        setEventsCaptureAlert({
                            color: "primary",
                            text: "No Event Found",
                            show: true,
                        });
                        setTabData((prevData) => {
                            return {
                                ...prevData,
                                eventsTableData: {
                                    rows: [],
                                    columns: [],
                                },
                            };
                        });
                    } else {
                        if (response.data.content == null) {
                            const text = response.data.info;
                            setEventsCaptureAlert({
                                color: "primary",
                                text: text,
                                show: true,
                            });
                        } else {
                            setEventFileList(response.data.content.list_data);
                            setTabData((prevData) => {
                                return {
                                    ...prevData,
                                    eventsTableData: {
                                        rows: response.data.content.table_data.rows,
                                        columns: response.data.content.table_data.columns,
                                    },
                                };
                            });
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const onEventCapturePlotButtonClick = () => {
        if (!selectedCustomer) {
            setEventsCaptureAlert({
                color: "primary",
                show: true,
                text: "Select a Customer",
            });
        } else if (!selectedFacility) {
            setEventsCaptureAlert({
                color: "primary",
                show: true,
                text: "Select a Facility",
            });
        } else if (!selectedEquipment) {
            setEventsCaptureAlert({
                color: "primary",
                show: true,
                text: "Select an Equipment ",
            });
        } else if (tabData.selectedEventFile === "") {
            setEventsCaptureAlert({
                color: "primary",
                text: "Select Event File",
                show: true,
            });
        } else if (isMovingAvgLengthInvalid) {
            setEventsCaptureAlert({
                color: "primary",
                text: "Please select value between 1 and 1024",
                show: true,
            });
        } else {
            setEventsCaptureAlert({ ...eventsCaptureAlert, show: false });
            setIsEventsCapturePlotLoading(true);
            if (selectedFreqCalculationType.value == "None") {
                setTabData((prevData) => {
                    return {
                        ...prevData,
                        plotData: {
                            time: [],
                            current: {
                                Ia: [],
                                Ib: [],
                                Ic: [],
                            },
                            voltage_ln: {
                                Va: [],
                                Vb: [],
                                Vc: [],
                            },
                        },
                        isEventsCapturePlotLoading: true,
                        plotMetaData: {
                            selectedEquipment: selectedEquipment,
                            selectedESA: tabData.selectedEventFile,
                            analysisType: selectedFreqCalculationType.value,
                            selectedVoltageType: selectedVoltageType,
                            movingAvgLength: movingAvgLength,
                        },
                        showPlot: true,
                    };
                });
            } else {
                setTabData((prevData) => {
                    return {
                        ...prevData,
                        plotData: {
                            time: [],
                            current: {
                                Ia: [],
                                Ib: [],
                                Ic: [],
                            },
                            voltage_ln: {
                                Va: [],
                                Vb: [],
                                Vc: [],
                            },
                            analysis: {
                                x_axis_a: [],
                                x_axis_b: [],
                                x_axis_c: [],
                                zc_a: [],
                                zc_b: [],
                                zc_c: [],
                            },
                        },
                        isEventsCapturePlotLoading: true,
                        plotMetaData: {
                            selectedEquipment: selectedEquipment,
                            selectedESA: tabData.selectedEventFile,
                            analysisType: selectedFreqCalculationType.value,
                            selectedVoltageType: selectedVoltageType,
                        },
                        showPlot: true,
                    };
                });
            }

            EventsAnalysisAPI.event_capture_plot_data(
                token,
                tabData.selectedEventFile.value,
                selectedEquipment.location_node_id,
                selectedFreqCalculationType.value,
                selectedEquipment.product_type,
                selectedVoltageType.value,
                movingAvgLength
            )
                .then((response) => {
                    if (response.status === 200) {
                        setTabData((prevData) => {
                            return {
                                ...prevData,
                                plotData: response.data.content.plot_data,
                                showPlot: true,
                                isEventsCapturePlotLoading: false,
                            };
                        });

                        setPlotsOrder({
                            eventsCapture: 1,
                            correlatingEvents: 2,
                            eventsTrending: 3,
                            eventsThreshold: 4,
                            eventsFeaturesPlot: 5,
                            selEventsTrendingPlot: 7,
                        });
                    }
                    setIsEventsCapturePlotLoading(false);
                })
                .catch((err) => {
                    setTabData((prevData) => {
                        return {
                            ...prevData,
                            isEventsCapturePlotLoading: false,
                        };
                    });
                    setIsEventsCapturePlotLoading(false);
                    console.log(err);
                });
        }
    };

    return (
        <div>
            <Row>
                <Col>
                    <h2 className="margin-top-10">Events Capture</h2>
                </Col>
            </Row>
            <Row>
                <Col className="event-data col-7">
                    <label>Select Date Range:</label>
                    <br></br>
                    <DateRangePicker
                        startDate={eventCaptureStartDate}
                        endDate={eventCaptureEndDate}
                        onStartDateChange={onEventCaptureStartDateChange}
                        onEndDateChange={onEventCaptureEndDateChange}
                    />
                </Col>
            </Row>
            <br />
            <Row>
                <Col className="col-7">
                    <label>Select Event File:</label>
                    <br></br>
                    <Dropdown
                        options={eventFileList}
                        value={tabData.selectedEventFile}
                        onChange={(value) => {
                            setTabData((prevState) => {
                                return {
                                    ...prevState,
                                    selectedEventFile: value,
                                    currentSelectedTimeOnEventsCaptureTab: currentFormatedDateTime,
                                };
                            });
                        }}
                    />
                    <br></br>
                </Col>
                <Col className={showSELMenu ? "col-5 d-none" : "col-5"}>
                    <label>Select Analysis Type:</label>
                    <br></br>
                    <Dropdown
                        options={freqCalculationOptions}
                        value={selectedFreqCalculationType}
                        onChange={(val) => setSelectedFreqCalculationType(val)}
                    />
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col>
                    <label>Enter length for moving average:</label>
                    <br></br>
                    <Col className="col-8 margin_left_-15 waveform-sec-ctn">
                        <Form.Control
                            type="number"
                            min={1}
                            max={1024}
                            value={movingAvgLength}
                            onInput={(newDuration) => onMovingAvgLengthChange(newDuration.target.value)}
                            isInvalid={isMovingAvgLengthInvalid}
                            required
                        />
                    </Col>
                </Col>
            </Row>
            <Row>
                <Col className="col-2">
                    {isEventsCapturePlotLoading ? (
                        <ReactLoading type="bars" height={50} width={50} color="#007bff" />
                    ) : (
                        <Button variant="primary" onClick={onEventCapturePlotButtonClick} className="plot_button">
                            Plot
                        </Button>
                    )}
                </Col>
                <Col className="col-5 eventsAlert">
                    <Alert
                        variant={eventsCaptureAlert.color}
                        show={eventsCaptureAlert.show}
                        onClose={() => setEventsCaptureAlert({ ...eventsCaptureAlert, show: false })}
                        dismissible
                    >
                        {eventsCaptureAlert.text}
                    </Alert>
                </Col>
            </Row>
        </div>
    );
}
