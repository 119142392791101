import authAxios from "../axios/authAxios";
import portalAxios from "../axios/portalAxios";

class TrendingAnalysisAPI {
    static get_15minute_plot_data2(
        accessToken,
        selectedFacility,
        selectedEquipment,
        location_node_id,
        selectedParameters,
        startDate,
        endDate,
        work_cycle,
        np_current,
        np_sf,
        timezone,
        v_type,
        i_noise
    ) {
        // If np is '---' then make it 0
        if (np_current === "---") {
            np_current = 0;
        }
        const param = [];
        for (const i in selectedParameters) {
            param.push(selectedParameters[i].value);
        }
        return authAxios.get("/v1/trending_analysis/15_min_trend_data/", {
            params: {
                facility_name: `${selectedFacility}`,
                node_name: `${selectedEquipment}`,
                location_node_id: `${location_node_id}`,
                selected_parameters: `${JSON.stringify(param)}`,
                start_date: `${startDate}`,
                end_date: `${endDate}`,
                work_cycle: `${work_cycle}`,
                np_i_sf: `${np_current * np_sf}`,
                timezone: `${timezone}`,
                v_type: `${v_type}`,
                i_noise: `${i_noise}`,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static get_15minute_plot_data(
        accessToken,
        selectedFacility,
        selectedEquipment,
        location_node_id,
        selectedParameters,
        startDate,
        endDate,
        work_cycle,
        np_current,
        np_sf,
        timezone,
        v_type,
        i_noise
    ) {
        // If np is '---' then make it 0
        if (np_current === "---") {
            np_current = 0;
        }
        const param = [];
        for (const i in selectedParameters) {
            param.push(selectedParameters[i].value);
        }
        return authAxios.get("/v1/trending_analysis/15_min_trend/", {
            params: {
                facility_name: `${selectedFacility}`,
                node_name: `${selectedEquipment}`,
                location_node_id: `${location_node_id}`,
                selected_parameters: `${JSON.stringify(param)}`,
                start_date: `${startDate}`,
                end_date: `${endDate}`,
                work_cycle: `${work_cycle}`,
                np_i_sf: `${np_current * np_sf}`,
                timezone: `${timezone}`,
                v_type: `${v_type}`,
                i_noise: `${i_noise}`,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static get_1second_new_plot_data(
        accessToken,
        location_node_id,
        eqpt_np_current,
        start_date,
        end_date,
        v_type,
        timezone
    ) {
        return authAxios.get("/v1/trending_analysis/1_sec_trending/", {
            params: {
                location_node_id: `${location_node_id}`,
                start_date: `${start_date}`,
                end_date: `${end_date}`,
                timezone: `${timezone}`,
                np_current: `${eqpt_np_current}`,
                v_type: `${v_type}`,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static getPortalRealTimeMetering(accessToken, location_node_id) {
        return portalAxios.get("/api/locations/" + location_node_id + "/metering", {
            headers: { Authorization: `Bearer ${accessToken}` },
            params: { internal: true },
        });
    }

    /*
     * @deprecated use getHourlyTrendData and plot on frontend
     */
    static getOneHourTrendingPlot(
        accessToken,
        location_node_id,
        selectedParameters,
        startDate,
        endDate,
        timezone,
        plot_title,
        np_current,
        np_sf
    ) {
        // If np is '---' then make it 0
        if (np_current === "---") {
            np_current = 0;
        }
        const param = [];
        for (const i in selectedParameters) {
            param.push(selectedParameters[i].value);
        }
        return authAxios.get("/v1/trending_analysis/1_hour_trend/", {
            params: {
                //facility_name: `${selectedFacilityLabel}`,
                //node_name: `${selectedEquipmentLabel}`,
                location_node_id: `${location_node_id}`,
                //selected_parameters: `${JSON.stringify(param)}`,
                start_date: `${startDate}`,
                end_date: `${endDate}`,
                plot_title: `${plot_title}`,
                //work_cycle: `${work_cycle}`,
                np_i_sf: `${np_current * np_sf}`,
                timezone: `${timezone}`,
                //v_type: `${v_type}`
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static getHourlyTrendData(accessToken, location_node_id, start_date, end_date, timezone, plots) {
        const param = [];
        for (const i in plots) {
            param.push(plots[i].value);
        }
        return authAxios.get("/v1/trending_analysis/sel_hourly_trend_data/", {
            params: {
                location_node_id: location_node_id,
                start_date: start_date,
                end_date: end_date,
                timezone: timezone,
                plots: JSON.stringify(param),
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static getSecondTrendData(
        accessToken,
        location_node_id,
        selected_nodes,
        start_date,
        end_date,
        timezone,
        plots,
        v_type,
        display_events,
        display_labels
    ) {
        const param = [];
        for (const i in plots) {
            param.push(plots[i].value);
        }
        const nodes = selected_nodes.map((eq) => {
            return {
                location_node_id: eq.location_node_id,
                eq_type: eq.eq_type,
                eq_type_sub: eq.eq_type_sub,
                i_noise: eq.i_noise,
            };
        });
        return authAxios.get("/v1/trending_analysis/second_trend_data/", {
            params: {
                location_node_id: location_node_id,
                selected_nodes: JSON.stringify(nodes),
                timezone: timezone,
                start_date: start_date,
                end_date: end_date,
                plots: JSON.stringify(param),
                v_type: v_type,
                display_events: display_events,
                display_labels: display_labels,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static getAccumulatedAmpHours(accessToken, location_node_id_list, start_date, end_date, timezone) {
        return authAxios.get("/v1/trending_analysis/accumulated_amp_hours/", {
            params: {
                location_node_id_list: JSON.stringify(location_node_id_list),
                start_date: start_date,
                end_date: end_date,
                timezone: timezone,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static getAccumulatedStarts(accessToken, location_node_id_list, start_date, end_date, timezone) {
        return authAxios.get("/v1/trending_analysis/accumulated_starts/", {
            params: {
                location_node_id_list: JSON.stringify(location_node_id_list),
                start_date: start_date,
                end_date: end_date,
                timezone: timezone,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static getFifteenMinuteTrend(
        accessToken,
        location_node_id,
        selected_nodes,
        timezone,
        start_date,
        end_date,
        plots,
        v_type,
        work_cycle,
        display_labels
    ) {
        const param = [];
        for (const i in plots) {
            param.push(plots[i].value);
        }
        const nodes = selected_nodes.map((eq) => {
            return {
                location_node_id: eq.location_node_id,
                work_cycle: eq.work_cycle,
                eq_type: eq.eq_type,
                eq_type_sub: eq.eq_type_sub,
                i_noise: eq.i_noise,
            };
        });
        return authAxios.get("/v1/trending_analysis/fifteen_minute_trend/", {
            params: {
                location_node_id: location_node_id,
                selected_nodes: JSON.stringify(nodes),
                timezone: timezone,
                start_date: start_date,
                end_date: end_date,
                plots: JSON.stringify(param),
                v_type: v_type,
                work_cycle: work_cycle,
                display_labels: display_labels,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static getRelayTrend(accessToken, location_node_id, timezone, start_date, end_date, plots) {
        const param = [];
        for (const i in plots) {
            param.push(plots[i].value);
        }
        return authAxios.get("/v1/trending_analysis/ge_relay_trend/", {
            params: {
                location_node_id: location_node_id,
                timezone: timezone,
                start_date: start_date,
                end_date: end_date,
                plots: JSON.stringify(param),
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static getCenteredOnZero(accessToken, location_node_id) {
        return authAxios.get("/v1/trending_analysis/centered_on_zero/", {
            params: {
                location_node_id: location_node_id,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }
}

export default TrendingAnalysisAPI;
