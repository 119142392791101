import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import Alert from "react-bootstrap/Alert";
import { useSelector, useDispatch } from "react-redux";
import { voltageTypeActions } from "../../../../store/volatgeTypeSlice/volatgeTypeSlice";

import "./css/VoltageTypeSelect.css";

const voltageTypeOptions = [
    { label: "Line to Line", value: "L-L" },
    { label: "Line to Neutral", value: "L-N" },
];

const VoltageTypeSelect = () => {
    const { selectedVoltageType } = useSelector((state) => state.voltageType);
    const selectedEquipment = useSelector((state) => state.equipmentsPortfolio.selectedEquipment);
    const dispatch = useDispatch();

    const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);
    const [alert, setAlert] = useState({
        text: "Feature not available for this Equipment",
        color: "primary",
        show: false,
    });

    const dropDownMem = useRef(selectedVoltageType);

    useEffect(() => {
        if (selectedEquipment) {
            if (
                selectedEquipment.product_type === "SEL" ||
                selectedEquipment.eq_type_sub === "v1" ||
                selectedEquipment.eq_type === "dc"
            ) {
                dropDownMem.current = selectedVoltageType;

                //sees if the current dropdown selection needs to change
                if (selectedVoltageType.value !== "L-L") {
                    dispatch(voltageTypeActions.setVoltageType({ label: "Line to Line", value: "L-L" }));
                }

                setIsDropdownDisabled(true);

                return;
            } else if (selectedEquipment.eq_type_sub === "i1") {
                dropDownMem.current = selectedVoltageType;

                if (selectedVoltageType.value !== "L-N") {
                    dispatch(voltageTypeActions.setVoltageType({ label: "Line to Neutral", value: "L-N" }));
                }
                setIsDropdownDisabled(true);
                return;
            } else {
                //Checks if dropdown was peviously disabled
                if (isDropdownDisabled) {
                    dispatch(voltageTypeActions.setVoltageType(dropDownMem.current));
                    setIsDropdownDisabled(false);
                }
            }
        } else {
            //Checks if dropdown was peviously disabled
            if (isDropdownDisabled) {
                dispatch(voltageTypeActions.setVoltageType(dropDownMem.current));
                setIsDropdownDisabled(false);
            }
        }
    }, [selectedEquipment]);

    return (
        <React.Fragment>
            <div
                onClick={() => {
                    // if (isDropdownDisabled) {
                    // 	setAlert({ ...alert, show: true });
                    // 	setTimeout(() => {
                    // 		setAlert({ ...alert, show: false });
                    // 	}, 4000);
                    // }
                }}
            >
                <Select
                    isDisabled={isDropdownDisabled}
                    options={voltageTypeOptions}
                    value={selectedVoltageType}
                    onChange={(val) => {
                        dispatch(voltageTypeActions.setVoltageType(val));
                    }}
                />
            </div>
            <br></br>
            <div className="dropdown-alert">
                <Alert variant={alert.color} show={alert.show}>
                    {alert.text}
                </Alert>
            </div>
        </React.Fragment>
    );
};

export default VoltageTypeSelect;
